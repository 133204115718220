<template>
  <v-container>
    <v-row align="center">
      <v-col cols="12" :sm="noImage ? 12 : 6" :order="order.def" :order-sm="order.sm">
        <p v-if="!noTitle" class="text-h4 text-capitalize" :class="{ 'text-center': noImage }">
          {{ title }}
        </p>
        <p
          v-for="(text, index) in textArray"
          :key="rand() + '_' + index"
          class="text-body-1"
          :class="{ 'text-center': centerText }"
        >
          {{ text }}
        </p>
      </v-col>
      <v-col v-if="!noImage" cols="12" sm="6">
        <v-img :src="image" max-height="400" contain />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'rcContentBasic',
  props: {
    noImage: { type: Boolean, default: false },
    noTitle: { type: Boolean, default: false },
    left: { type: Boolean, default: false },
    centerText: { type: Boolean, default: false },
    image: { type: String, default: '/img/assets/rc-ph.png' },
    title: { type: String, default: 'insert headline' },
    textArray: {
      type: Array,
      default() {
        return [
          'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Natus animi sed earum provident rerum perspiciatis voluptates nihil, est laudantium corporis asperiores rem doloribus minus nesciunt tempore. Est modi alias quibusdam.'
        ]
      }
    }
  },
  computed: {
    order() {
      if (this.left) {
        const result = {
          def: 1,
          sm: 0
        }
        return result
      } else {
        const result = {
          def: 0,
          sm: 1
        }
        return result
      }
    }
  },
  methods: {
    rand() {
      return Math.floor(Math.random() * 100)
    }
  }
}
</script>

<style></style>
