<template>
  <div class="contact">
    <v-container class="mb-16">
      <rc-layout-page-header :title="'contact'" />
      <rc-content-basic class="py-8" :title="contact.title" :text-array="contact.content" noImage center-text noTitle />
      <v-row justify="center">
        <v-col cols="6">
          <v-row align="center" justify="center" class="my-2">
            <v-col cols="4" class="text-right"><v-icon>mdi-map-marker-outline</v-icon></v-col>
            <v-col cols="8" class="">
              <p>Ryan Capehart<br />Spring, TX</p>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" class="my-2">
            <v-col cols="4" class="text-right"><v-icon>mdi-phone-outline</v-icon></v-col>
            <v-col cols="8" class="">(832) 246-5044‬</v-col>
          </v-row>
          <v-row align="center" justify="center" class="my-2">
            <v-col cols="4" class="text-right"><v-icon>mdi-email-outline</v-icon></v-col>
            <v-col cols="8" class="">ryan@ryancapehart.com</v-col>
          </v-row>
          <v-divider></v-divider>
          <social-links class="my-2" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import rcLayoutPageHeader from '@/components/rcLayout/rcLayoutPageHeader'
import socialLinks from '@/components/rcMain/rcContactSocialLinks.vue'
import rcContentBasic from '@/components/rcLayout/rcContentBasic.vue'

export default {
  name: 'Contact',
  components: { rcLayoutPageHeader, rcContentBasic, socialLinks },
  data() {
    return {
      contact: {
        content: ["Reach out and let's find the solution that works for you!"]
      }
    }
  }
}
</script>

<style></style>
