<template>
  <div class="services">
    <v-container>
      <rc-layout-page-header :title="'services'" />
      <rc-services-short-description class="pt-16" />
    </v-container>
  </div>
</template>

<script>
import rcLayoutPageHeader from '@/components/rcLayout/rcLayoutPageHeader'
import rcServicesShortDescription from '@/components/rcMain/rcServicesShortDescription.vue'
// import rcContentBasic from '@/components/rcContent/rcContentBasic.vue'

export default {
  name: 'Services',
  components: { rcServicesShortDescription, rcLayoutPageHeader },
  data() {
    return {
      contact: {
        title: 'How can I help?',
        content: ["Reach out and let's find the solution that works for you!"]
      },
      design: {
        title: 'design',
        content: [
          'The challenge of effective web design is more than just being noticed, it is in optmizing your users experience to keep them engaged and wanting to return.',
          'Feature rich, engaging, quick pages.',
          ''
        ],
        image: '/img/banners/coding.jpg'
      },
      development: {
        title: 'development',
        content: [
          'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quo quos, reprehenderit rerum repellat tenetur facilis voluptates beatae cupiditate enim vel placeat accusamus cum corporis. Vel eligendi reiciendis ab quis doloribus!',
          'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quo quos, reprehenderit rerum repellat tenetur facilis voluptates beatae cupiditate enim vel placeat accusamus cum corporis. Vel eligendi reiciendis ab quis doloribus!'
        ],
        image: '/img/banners/coding.jpg'
      },
      consulting: {
        title: 'consulting',
        content: [
          'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quo quos, reprehenderit rerum repellat tenetur facilis voluptates beatae cupiditate enim vel placeat accusamus cum corporis. Vel eligendi reiciendis ab quis doloribus!',
          'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quo quos, reprehenderit rerum repellat tenetur facilis voluptates beatae cupiditate enim vel placeat accusamus cum corporis. Vel eligendi reiciendis ab quis doloribus!'
        ],
        image: '/img/banners/coding.jpg'
      }
    }
  }
}
</script>

<style></style>
