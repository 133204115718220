<template>
  <div class="home">
    <v-parallax src="/img/banners/downtown-houston.jpg">
      <v-container class="trans-overlay" fluid fill-height>
        <v-row>
          <v-col offset-sm="0" cols="12" sm="12" class="">
            <v-row justify-sm="start">
              <v-col align-self="center" cols="12" class="text-h2 pl-12">Ryan Capehart</v-col>
              <v-col align-self="center" cols="12" class="pl-12">
                <ul class="text-h5 list">
                  <li>captioning and transcriptions</li>
                  <li>web development and design</li>
                </ul>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-parallax>

    <services class="py-6" />

    <about class="py-6" />

    <contact class="py-6" />
  </div>
</template>

<script>
import About from '@/components/rcMain/rcHomePageAbout.vue'
import Contact from '@/components/rcMain/rcHomePageContact.vue'
import Services from '@/components/rcMain/rcHomePageServices.vue'

export default {
  name: 'rcMain',
  components: {
    About,
    Contact,
    Services
  },
  data() {
    return {}
  }
}
</script>

<style>
.v-parallax__content {
  padding: 0px !important;
}
.trans-overlay {
  background-color: rgba(26, 20, 20, 0.65);
}
</style>
