<template>
  <v-container>
    <rc-layout-page-header :title="'about'" />
    <rc-content-basic no-title :text-array="history" :image="headshot" />
  </v-container>
</template>

<script>
import rcLayoutPageHeader from '@/components/rcLayout/rcLayoutPageHeader'
import rcContentBasic from '@/components/rcLayout/rcContentBasic'

export default {
  name: 'rcHomePageAbout',
  components: { rcLayoutPageHeader, rcContentBasic },
  data() {
    return {
      headshot: '/img/assets/ryan-headshot.png',
      history: [
        'I started my career in captioning in 2008 with providing real-time captioning at a local high school. Since I have expanded my experience by providing ADA compliant captioning to hard of hearing students at local colleges. Working with those new to college as well as those finishing their doctorates.',
        'I have always been passionate about computers and began learning the basics of web development and scripting in 2004. From then until 2018 I worked on several small projects to automate small tasks for myself, as well as some community projects that were interesting to me. In 2018 I decided I wanted to turn my hobby into a career and began expanding my skills to include full-stack web-development. In 2021 I began delving into Android app development as well.'
      ]
    }
  }
}
</script>

<style></style>
