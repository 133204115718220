<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12" sm="6">
        <p class="text-h4">captioning and transcriptions</p>
        <p><v-icon x-large color="primary">mdi-keyboard</v-icon></p>
        <p class="text-body-1">Live or remote real-time captioning and audio/video transcriptions.</p>
      </v-col>
      <v-col cols="12" sm="6">
        <p class="text-h4">web development and design</p>
        <p class="text-h4">{<v-icon x-large color="primary">mdi-web</v-icon>}</p>
        <p class="text-body-1">
          Developing unique, engaging, powerful, and feature-rich web apps. Database-driven responsive designs custom
          built based on your needs.
        </p>
        <p class="text-body-1">
          Comprehensive web design services for a variety of needs from basic templates to advanced, custom websites.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'rcServicesShortDescription'
}
</script>

<style></style>
