<template>
  <v-row>
    <v-col>
      <div class="my-6 text-h2 text-center text-uppercase">{{ title }}</div>
      <v-divider></v-divider>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'rcLayoutPageHeader',
  props: {
    title: { type: String, required: true }
  }
}
</script>

<style></style>
